import React, { useContext, useEffect } from 'react'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath } from '../../domain/locale'
import { PRODUCTS_PATH } from '../../domain/path'
import { ChevronRightIcon } from '@heroicons/react/solid'
import Layout from '../../components/layout/Layout'
import Seo from '../../components/layout/Seo'

function ProductsPage() {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.ID)
    }, [])

    return (
        <PageContext.Provider
            value={{
                basepath: PRODUCTS_PATH,
                locale: Locale.ID,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Products"
                    titleCompany="Telobag ID"
                    description="Telobag makes cassava-starch bags that are organic and thus 100% biodegradable and compostable"
                    relativeUrl={localisePath(PRODUCTS_PATH, Locale.ID)}
                />

                {/* Title and short horizontal rule underneath */}
                <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                    Products
                </h1>
                <hr className="border-b border-gainsboro w-10" />

                {/* Product categories displayed as cards */}
                <div className="mt-6 flex flex-col">
                    <div className="my-2">
                        <a
                            href="https://www.tokopedia.com/telobag"
                            className="inline-flex items-center bg-primary hover:bg-primary_dark px-4 py-3 rounded-lg text-white focus:outline-none"
                        >
                            Visit our Tokopedia store
                            <ChevronRightIcon className="h-6 w-6 ml-2" />
                        </a>
                    </div>

                    <div className="my-2">
                        <a
                            href="https://shopee.co.id/telobag.jakarta"
                            className="inline-flex items-center bg-primary hover:bg-primary_dark px-4 py-3 rounded-lg text-white focus:outline-none"
                        >
                            Visit our Shopee store
                            <ChevronRightIcon className="h-6 w-6 ml-2" />
                        </a>
                    </div>
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default ProductsPage
